<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
          transition="scale-transition"
          width="40"
        />

        <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
          width="100"
        />
      </div>
      <v-spacer></v-spacer>

      <v-btn text v-if="$keycloak.authenticated" @click="logout()" to="/">
        <span class="mr-2" >Log out</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
      <v-btn text v-else to="/home">
        <span class="mr-2" >Log in</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>

    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component'

@Component
export default class App extends Vue{

  logout() {
    Vue.$keycloak.logout()
  }
}
</script>
