<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/logo.svg')"
          class="my-3"
          contain
          height="200"
        />
      </v-col>

      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Willkommen im KI-Gateway
        </h1>
        <h3 class="subheading font-weight-regular">
          Eine einheitliche Schnittstelle zu den KI-Microservices
        </h3>
      </v-col>

      <v-col
        class="mb-5"
        cols="12"
      >
        <h2 class="headline font-weight-bold mb-3">
          Was ist das?
        </h2>

        <v-row justify="center">
          <p>
            Mithilfe dieser Web-Applikation können sie alle im Bildungsnetzwerk befindlichen KI-Microservices erkunden und Aufträge an selbige stellen. 
            KI-Microservices sind auf Python basierte Services, welche auf die im Netzwerk erhobenen Daten zugreifen und Maschine-Lerning Algorithmen darauf ausführen.     
            Jeder KI-Microservice bearbeitet dabei genau eine Problemstellung so, dass die entstehenden Modelle über die Zeit immer weiter trainert werden.
          </p>
        </v-row>
      </v-col>

      <v-col
        class="mb-5"
        cols="12"
      >
        <h2 class="headline font-weight-bold mb-3">
          Wie kann ich es nutzen?
        </h2>

        <v-row justify="center">
          <p>
            Neben dieser Web-Applikation können die Schnittstellen des Gateways auch durch Dritt-Services direkt angefragt werden, um ihnen den Zugang zu den KI-Ergebnissen zu ermöglichen.  
            Die Anfragen dafür müssen in einer authorisierten Weise geschehen. 
          </p>
        </v-row>
      </v-col>

      <v-col
        class="mb-5"
        cols="12"
      >
        <v-row justify="center space-between">
            <v-img 
              max-width="5%"
              src="https://gitlab.com/uploads/-/system/project/avatar/278964/logo-extra-whitespace.png"
              @click="linkGitlab"
            ></v-img>
            <v-img 
              max-width="5%"
              src="https://tu-dresden.de/mz/ressourcen/bilder/logos/logo_tech4comp.png/@@images/3af9b44e-7e20-4d9b-8ca6-e536723f4e63.png"
              @click="linkT4C"
            ></v-img>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class WelcomeView extends Vue{
    
  linkGitlab() {
    window.open('https://gitlab.com/Tech4Comp', '_blank').focus();
  }

  linkT4C() {
    window.open('https://tech4comp.de/', '_blank').focus();
  }
}
</script>
