import Vue from 'vue'
import Keycloak from 'keycloak-js'

// no secret provided because there is no secure way to store it in client side
const options = {
  url: 'https://auth.las2peer.org/auth/',
  realm: 'main',
  clientId: 'ki-gateway-fe',
}

let _keycloak = new Keycloak(options)

const Plugin = {
  install(Vue) {
    Vue.$keycloak = _keycloak
  }
}

Plugin.install = Vue => {
  Vue.$keycloak = _keycloak
  Object.defineProperties(Vue.prototype, {
    $keycloak: {
      get() {
        return _keycloak
      }
    }
  })
}

Vue.use(Plugin)

export default Plugin